import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from '../components/header/hero'
import Newsletter from '../components/footer/newsletter'

export default ({ data }) => {
  const {pg} = data
  const {acf} = pg
  console.log(data)
  return (
    <Layout mode="dark">
      <SEO title={pg.yoast_title} meta={pg.yoast_meta} path={pg.slug} />
        <style>{`#hero {min-height: 30vh;}`}</style>
        <Hero
          mode="default"
          title={acf.header_text}
          content=""
          image={ pg.featured_media ? pg.featured_media.localFile.childImageSharp.fluid : null }
          button={{
            title: !!acf?.header_button?.title ? acf.header_button.title : '',
            url: !!acf?.header_button?.url ? acf.header_button.url : '',
            target: !!acf?.header_button?.target ? acf.header_button.target : '',
          }}
        />
        
          <div className="pp">
              <div className="pp__container">
                  <div dangerouslySetInnerHTML={{__html: acf?.content}} />
              </div>
          </div>

        <Newsletter />
    </Layout>
  )
}
export const pageQuery = graphql`
query PrivacyQuery {
  pg: wordpressPage(wordpress_id: {eq: 3}) {
    title
    slug
    acf {
      header_text
      header_button {
        url
        title
        target
      }
      content
    }
    yoast_meta {
      name
      property
    }
    yoast_title
    featured_media {
      source_url
      localFile {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1980) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
}
`